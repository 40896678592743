import PropTypes from "prop-types"
import React, {useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container, Table } from "reactstrap"
import { Col, Grid, List, Row } from "rsuite"
import "./restructure.scss"
import Dropdown from 'react-bootstrap/Dropdown';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap"
import { connect, useDispatch } from "react-redux"
import axios from "axios"
// Redux
import {
  withRouter,
  Link,
  useHistory,
  Redirect,
  useParams,
} from "react-router-dom"
import { ApiRoute } from "common/ApiRoute"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import moment from 'moment';
import Paynow from '../Paynow'
import DisputeAmount from '../DisputeAmount'
import Swalalert from 'sweetalert2';
import FaqList from "../Common/FaqList"



function AmountRestructure() {

  const [active, setActive] = useState({
    id1: false,
    id2: false,
    id3: false,
  })
  

    const date = moment();
    const dispatch = useDispatch()
    const api_url = ApiRoute
    const history = useHistory()
    const params = useParams()
    const [getofferdetails, setgetofferdetails] = useState(null);
    const [payshowAlert, setpayShowAlert] = useState(true);

    const [offersdata, setOffersdata] = useState([]);
    const [action, setAction] = useState(false)
    const [request, setRequest] = useState({
      user_id: null,
      data: null,
      token: null,
      notice_id: null,
      offer_id: null,
      files: null
      
    })
  const [restructuredata, setrestructuredata] = useState([]);

  const [notice_unq_id, setnotice_unq_id] = useState([]);
  const [replyData, setReplyData] = useState('');
  const [selectedofferName, setSelectedName] = useState('');

  function handleOfferChange(event) {

    const selectedOption = offersdata.find((offer) => offer.offer_no == event);
    if (selectedOption) {
      setgetofferdetails(selectedOption.name)

    }
  } 
  useEffect(() => {

      var values = {
        user_id: 1,
        notice_id: params.notice_id,
        token: params.token,
      }
      axios.post(api_url + "offerCheck", values).then(response => {
        setOffersdata(response?.data?.responseData),
          setRequest({
            ...request,
            data : response?.data?.responseData,
          })
      })

      const handleScroll = () => {

        if (window.scrollY > 100 && payshowAlert) {
          Swalalert.fire('It is recommended to pay the full outstanding amount to avoid any implications on your creditworthiness, prevent incurring additional interest, penalties etc.');
          setpayShowAlert(false); 
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      }; 
  }, [payshowAlert]);

  const [data, setData] = useState('');
  const handleLinkClick = (notice_id, token, offer_name) => () => {
    try {
      var values = {
        notice_id: params.notice_id,
        token: params.token,
        offer_name: offer_name
      }
      axios.post(api_url + "storeRestructure", values).then(response => {
        setrestructuredata(response?.data?.responseData),
          setRequest({
            ...request,
            data : response?.data?.responseData,
          })
          if (response?.data?.response === "success") {
            ShowSwal(
              "Success",
              "Thank you for confirming the offer. We will get back to you",
              "info",
              "Ok"
            )
          } else {
            ShowNotification(response?.data?.message, "danger")
          }
      })
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  return (

    <div>
       {offersdata  ? (
        <CardBody className="p-4 body">
          <div className="row mb-3">
            <div className="col-md-12 text-center">
              <h5>
          Explore available offers for  restructuring that may result in changes to your interest rate, repayment term, and/or monthly payment amounts. Be aware that this option may impact your credit score and financial situation.
          </h5>
          <p>
            You can easily view the available offers by clicking on the drop down button below.
          </p>
          <Row className="show-grid ">
            <Col xs={24} sm={24} md={24} lg={24}>
          {/* <Col md={6} lg={6}> */}
          <Dropdown onSelect={handleOfferChange} style={{ textAlign: 'center' }}  >
            <Dropdown.Toggle variant="secondary" id="offer-dropdown" style={{color: 'black'}}>
              Select an offer
            </Dropdown.Toggle>
            <div className="row mb-3">
            <div className="col-md-12 text-center">
            <Row className="show-grid ">
            <Col xs={24} sm={24} md={24} lg={24}>
            <Dropdown.Menu style={{ "margin-left": "440px" }}>
              {offersdata.map(offer => (
                <Dropdown.Item key={offer.offer_no} eventKey={offer.offer_no}>
                  {offer.value}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
            </Col>
            </Row>
            </div>
            </div>
          </Dropdown>
          </Col>
         {/*  </Col> */}
  {/*         <Col md={6} lg={6}>
          </Col> */}
          </Row>
          </div>
          </div>
        </CardBody>
      ): (

        <CardBody className="p-4 body">
          <div className="row mb-3">
            <div className="col-md-12 text-center">
              <p>
                 Restruction Options available.
             </p>
           </div>
          </div>
        </CardBody>
        

      )}

       <CardBody className="p-4 body">
       <div className="row mb-3">
        <div className="col-md-12 text-center"  style={{ textAlign: 'center' }}>
        {getofferdetails && 
        <Row className="show-grid">
          <div>
            <p>{getofferdetails}</p>
            <Button type="button" className="my-4"  color="warning" size="md"  onClick={handleLinkClick(params.notice_id, params.token, getofferdetails)}>Confirm the offer</Button>{" "}
          </div>
        </Row>}
        </div>
        </div>
       </CardBody>
       <CardBody className="p-4 body">
       </CardBody>
       <CardBody className="p-4 body">
       </CardBody>
       <CardBody className="p-4 body">
            <p>
            </p>
       </CardBody>
     </div>
     
  )
}

export default AmountRestructure
