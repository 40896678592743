import React, { useEffect } from "react"

import { Switch, Route, BrowserRouter as Router } from "react-router-dom"
import { ReactNotifications } from "react-notifications-component"
import "react-notifications-component/dist/theme.css"

import {
  adminRoutes,
  authRoutes,
  userRoutes,
  homeRoutes,
} from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"


import AdminVerticalLayout from "./components/AdminVerticalLayout"
import UserVerticalLayout from "components/UserVerticalLayout"
import { useDispatch, useSelector } from "react-redux"

import HomePageLayout from "./components/HomePageLayout"
import Pagemiddleware from "routes/middleware/Pagemiddleware"
import MainPage from "./pages/Home/MainPage/index"
import Reply from "./pages/Home/Reply/index"
import ScrollToTop from "common/ScrollToTop"
import { refreshToken } from "store/actions"

const App = () => {
  const token = useSelector(state => state.Login.token)
  const dispatch = useDispatch()

  useEffect(() => {
    let interval = setInterval(() => {
      if (token) {
        dispatch(refreshToken({ token: token }))
      }
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [token])

  return (
    <React.Fragment>
      <ReactNotifications />

      <Router>
        <ScrollToTop>
          <Switch>
            <Route
              exact
              path="/"
              render={props => {
                return (
                  <HomePageLayout>
                    <MainPage {...props} />
                  </HomePageLayout>
                )
              }}
            />
            <Route
              path="/reply/:id"
              render={props => {
                return (
                  <HomePageLayout>
                    <Reply {...props} />
                  </HomePageLayout>
                )
              }}
            />
            {homeRoutes.map((route, idx) => (
              <Pagemiddleware
                path={route.path}
                layout={HomePageLayout}
                component={route.component}
                key={idx}
              />
            ))}
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={HomePageLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={UserVerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                token={token}
              />
            ))}
            {adminRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={AdminVerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                token={token}
              />
            ))}
          </Switch>
        </ScrollToTop>
      </Router>
    </React.Fragment>
  )
}

export default App
