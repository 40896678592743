import FileDownloadIcon from "@rsuite/icons/FileDownload"
import EyeIcon from "@rsuite/icons/legacy/Eye"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import { RouteWithoutApi } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import useWindowDimensions from "common/useWindowDimensions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import SearchIcon from '@rsuite/icons/Search';
import { IconButton, Pagination, Table ,Input, InputGroup, Grid, Row, Col } from "rsuite"
import { getAutoReport } from "store/actions"

const { Column, HeaderCell, Cell } = Table
const styles = {
  width: 300,
  marginLeft:'auto'
};
const AutoReportTable = props => {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()
const [searchQuery,setsearchQuery] = useState("")
  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickFile = (file, natureOfNotice, id) => {
    if (natureOfNotice === "USER") {
      const request = {
        token: props?.token,
        folder: "auto_report",
        filename: file,
      }
      DownloadApiCall(request, "download/single-download", "_blank")
    } else {
      proxyNoticeReportDownload(file, id)
    }
  }

   const SearchAutoreportList =() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      batchName:searchQuery
    }
    dispatch(getAutoReport(requestPayload))
   }

  const proxyNoticeReportDownload = async (file, id) => {
    console.log("Performing proxy report download action.")

    const formData = new FormData()
    formData.append("token", props?.token)
    formData.append("folder", "auto_report")
    formData.append("report_type", file)
    formData.append("id", id)

    try {
      const response = await fetch(
        RouteWithoutApi + "api/download/proxyReport",
        {
          method: "POST",
          body: formData,
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      if (data.code === 200 && data.response === "success") {
        const base64Zip = data.base64_zip
        const binaryString = window.atob(base64Zip)
        const len = binaryString.length
        const bytes = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }

        const blob = new Blob([bytes], { type: "application/zip" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = data.filename || "proxyReport.zip"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)

        ShowNotification("", "Successfully Downloaded!", "success", "Ok")
      } else {
        ShowNotification(
          data.message ? data.message : "File Not Found!",
          "danger"
        )
      }
    } catch (error) {
      ShowNotification(
        error.message ? error.message : "An error occurred!",
        "danger"
      )
    }
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      batchName:searchQuery
    }
    dispatch(getAutoReport(requestPayload))
  }, [page, limit, props?.apiAction?.action])

  return (
    <div className="mt-4">
       <InputGroup onChange={(e)=>{
         setsearchQuery(e.target.value)
       }} onKeyDown={(e)=>{
        if(e.key === "Enter"){
          SearchAutoreportList()
        }

       }} style={styles}>
      <Input/>
      <InputGroup.Addon>
        <SearchIcon onClick={()=>{
               SearchAutoreportList()
        }} />
      </InputGroup.Addon>
    </InputGroup>
      <h4>View Auto Report</h4>
     
      <div className="table-responsive mt-4">
        <Table
          height={height - 350}
          data={props?.data}
          wordWrap="break-word"
          headerHeight={46}
          loading={props?.tableLoading}
        >
            
          <Column width={80} align="center">
            <HeaderCell>Sr.No.</HeaderCell>
            <Cell>
              {(rowdata, key) => (
                <>
                  <span>
                    {page === 1 ? key + 1 : limit * (page - 1) + key + 1}
                  </span>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Notice</HeaderCell>
            <Cell dataKey="notice_type" />
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Batch</HeaderCell>
            <Cell dataKey="batch_name" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Report On</HeaderCell>
            <Cell dataKey="report_on" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Normal</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={
                          rowdata?.normal_report === null &&
                          rowdata?.nature_of_notice === "USER"
                        }
                        onClick={() => {
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "normal_report"
                              : rowdata?.normal_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }}
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Preliminary</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={
                          rowdata?.preliminary_report === null &&
                          rowdata?.nature_of_notice === "USER"
                        }
                        onClick={() =>
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "preliminary_report"
                              : rowdata?.preliminary_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Interim</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={
                          rowdata?.interim_report === null &&
                          rowdata?.nature_of_notice === "USER"
                        }
                        onClick={() =>
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "interim_report"
                              : rowdata?.interim_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Final</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Download File"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="green"
                      icon={<FileDownloadIcon />}
                      disabled={
                        rowdata?.final_report === null &&
                        rowdata?.nature_of_notice === "USER"
                      }
                      onClick={() =>
                        downloadClickFile(
                          rowdata?.nature_of_notice === "PROXY"
                            ? "final_report"
                            : rowdata?.final_report,
                          rowdata?.nature_of_notice,
                          rowdata?.id
                        )
                      }
                    />
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Canva</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="View Canva Report"
                  >
                    <Link
                      to={{
                        pathname:
                          RouteWithoutApi + "autoCanvaReport/" + rowdata?.id,
                      }}
                      target="_blank"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="blue"
                        disabled={rowdata?.final_report === null}
                        icon={<EyeIcon />}
                      />
                    </Link>
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column>
        </Table>
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.tableCount}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.AutoReport.items,
  tableLoading: state.AutoReport.loading,
  tableCount: state.AutoReport.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(AutoReportTable)
