
import React, {useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container, Table ,   Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap"
import { Col, Grid, Row } from "rsuite"
import "./paynow.scss"
import { connect, useDispatch } from "react-redux";
// Redux
import {
  withRouter,
  useHistory,
  Redirect,
  useParams,
} from "react-router-dom"

import { ApiRoute } from "common/ApiRoute"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import axios from "axios"


function Paynow() {

  const dispatch = useDispatch()
  const api_url = ApiRoute
  const history = useHistory()
  const params = useParams()

  const [paymentData, setpaymentData] = useState([]);




  useEffect(() => {
    var values = {
      notice_id: params.notice_id,
      token: params.token,
    }
    
}, []);
  
  return (
    <div>
      <Grid fluid>
        <Row>
            <h5>
            Pay the full outstanding amount to keep your current interest rate, repayment term, and monthly payment amount unchanged. This option will not impact your credit score or financial situation.
            </h5>
            <p>
               You can easily pay the full  amount by clicking on the button below.
            </p>
            <Row className="show-grid text-center">
              <Button type="button" className="my-4" color="warning" size="md" onClick={event =>  window.location.href=api_url+'pay-processed/'+params.notice_id+'/'+params.token} > Pay Full Amount </Button>{" "}
            </Row>
           {/*  <p>
            Incase360 offers you a wide range of payment options to make your payment conventient and hassle-free.
            </p> */}
        </Row>
      </Grid>
    </div>
  )
}

export default Paynow
