import "./AddNewProxyNotice.css"

import axios from "axios"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ApiRoute } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap"
import { CheckPicker, CustomProvider } from "rsuite"

function NoticeForm(props) {
  const location = useLocation()
  const [Express, setExpress] = useState(false)
  const [addedNotices, setAddedNotices] = useState([])
  const requestData = {
    noticeType: null,
    noticeDescription: null,
    draft_notice_html: location?.state?.draft_notice_html || null,
    willLinkExpire: 0,
    send_as: 2,
    autoGeneratePdf: 1,
    token: props?.token,
    bulk_csv: null,
    userId: null,
    canvaSubtitle: null,
    emailEnabled: 1,
    smsEnabled: 1,
    whatsappEnabled: 1,
    rpadEnabled: 1,
    pdfGenerationStrategy: "STANDARD",
    addedNotices: [],
    natureOfNotice: "PROXY",
  }
  const [request, setRequest] = useState(requestData)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const history = useHistory()

  const userdata = props?.userdata?.map(item => ({
    label: item?.organization_name,
    value: item?.id,
  }))

  const handleFileChange = file => {
    setRequest({ ...request, bulk_csv: file })
  }

  const handleLinkExpireChange = value => {
    setRequest({ ...request, willLinkExpire: value })
  }

  const handleSendAsChange = value => {
    setRequest({ ...request, send_as: value })
  }

  const handleAutoGeneratePdfChange = value => {
    setRequest({ ...request, autoGeneratePdf: value })
  }
  const handleCommunicationModeChange = (mode, isChecked) => {
    let updatedRequest = { ...request }
    switch (mode) {
      case 1: // Email
        updatedRequest.emailEnabled = isChecked ? 1 : 0
        break
      case 2: // SMS
        updatedRequest.smsEnabled = isChecked ? 1 : 0
        break
      case 3: // WhatsApp
        updatedRequest.whatsappEnabled = isChecked ? 1 : 0
        break
      case 4: // RPAD
        updatedRequest.rpadEnabled = isChecked ? 1 : 0
        break
      default:
        break
    }

    setRequest(updatedRequest)
  }

  const handleSubmit = e => {
    e.preventDefault()
    // request?.bulk_csv && setShowError({ ...showError, bulk_csv: true })
    // request?.bulk_csv &&
    request?.noticeType &&
      request?.noticeDescription &&
      request?.userId &&
      ApiCall(request, "admin/save-proxy-notice", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          history.push("/admin/existingnotice")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  const handlePreview = () => {
    const passData = {
      token: props?.token,
      noticeContent: request?.draft_notice_html,
    }
    ApiCall(passData, "previewNoticeContent", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        const link = document.createElement("a")
        // link.target = "_blank"
        link.target = "_blank"
        link.href = result?.data?.url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const selectUser = e => {
    if (e) {
      const payload = new FormData()
      payload.append("userId", e)
      axios
        .post(`${ApiRoute}getAssignedNoticeByUserId`, payload)
        .then(response => {
          setNotices(
            response.data.response.map(item => {
              return {
                notice_id: item.notice_id,
                notice_type: item.notice_name,
              }
            })
          )
          setAddedNotices(props?.request.assignedNotices)
        })
        .catch(error => {
          console.error("Error:", error)
        })
    }
    setRequest({ ...request, userId: e })
  }

  // code for dragable elements
  const [notices, setNotices] = useState([])

  useEffect(() => {
    setRequest({
      ...request,
      addedNotices: addedNotices.map(obj => obj.notice_id),
    })
  }, [addedNotices])

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const sourceList = result.source.droppableId
    const destinationList = result.destination.droppableId

    // If dragging within the "Available Notices" list, do nothing
    if (sourceList === destinationList && sourceList === "notices") {
      return
    }

    const draggedNotice = notices[result.source.index]

    if (sourceList === "notices" && destinationList === "addedNotices") {
      // Remove the dragged notice from the "Available Notices" list
      const updatedNotices = notices.filter(
        (notice, index) => index !== result.source.index
      )
      setNotices(updatedNotices)

      // Add the dragged notice to the "Add Notice" list
      setAddedNotices(prevNotices => [...prevNotices, draggedNotice])
    }

    if (sourceList === "addedNotices" && destinationList === "notices") {
      const updatedAddedNotices = addedNotices.filter(
        (notice, index) => index !== result.source.index
      )
      setAddedNotices(updatedAddedNotices)
    }
  }

  return (
    <div>
      {/* <Toggle
        style={{ marginLeft: "85%" }}
        checkedChildren="Express"
        onChange={isChecked => {
          setExpress(isChecked)
          isChecked
            ? setRequest({ ...request, pdfGenerationStrategy: "EXPRESS" })
            : setRequest({ ...request, pdfGenerationStrategy: "STANDARD" })
        }}
        unCheckedChildren="Standard"
      /> */}
      <Form method="post" onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="noticeType">Notice Type :</Label>
          <Input
            type="text"
            name="noticeType"
            id="noticeType"
            placeholder="Enter the name of the notice"
            onChange={e =>
              setRequest({ ...request, noticeType: e.target.value })
            }
          />
          <FormFeedback>Notice Type Filed is Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="noticeDescription">
            Notice Description(max: 100 words) :
          </Label>
          <Input
            type="textarea"
            name="noticeDescription"
            id="noticeDescription"
            placeholder="Enter Notice Description"
            onChange={e =>
              setRequest({ ...request, noticeDescription: e.target.value })
            }
          />
          <FormFeedback>Notice Description Filed is Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="noticeDescription">
            Assign User :
          </Label>
          <br />
          <CustomProvider theme="dark">
            {/* <SelectPicker
              size="sm"
              label="User"
              data={userdata}
              className="w-100"
            /> */}
            <CheckPicker
              size="sm"
              data={userdata}
              placeholder="Select User"
              onChange={e => selectUser(e)}
              block
            />
          </CustomProvider>
        </FormGroup>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="drag-drop-context">
            <Droppable droppableId="notices">
              {provided => (
                <div
                  className="draggable-container"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {" "}
                  <Label className="mt-3" for="noticeDescription">
                    Available Notices
                  </Label>
                  {notices.map((notice, index) => (
                    <Draggable
                      key={index}
                      draggableId={notice.notice_id}
                      index={index}
                    >
                      {provided => (
                        <div
                          className="draggable-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span>{notice.notice_id}</span> -{" "}
                          <span>{notice.notice_type}</span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId="addedNotices">
              {provided => (
                <div
                  className="draggable-container"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <Label className="mt-3" for="noticeDescription">
                    Add Notice
                  </Label>
                  {addedNotices.map((notice, index) => (
                    <Draggable
                      className
                      key={index}
                      draggableId={notice.notice_id}
                      index={index}
                    >
                      {provided => (
                        <div
                          className="draggable-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span>{notice.notice_id}</span> -{" "}
                          <span>{notice.notice_type}</span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <div>{/* Your form content here */}</div>
          </div>
        </DragDropContext>
        <Button
          type="submit"
          className="my-4"
          color="primary"
          size="md"
          disabled={buttonDisabled}
        >
          Submit
        </Button>{" "}
      </Form>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(NoticeForm)
