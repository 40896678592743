import React, { useEffect, useState } from "react"
import axios from "axios"
import FormGroup from "components/Common/FormGroup"
import { Button, Col, Grid, Input, Row } from "rsuite"
import { ApiRoute } from "common/ApiRoute"
import { connect } from "react-redux"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"

function DownloadReport(props) {
  const [request, setRequest] = useState({
    token: props?.token,
    mnid: "",
    dateFrom: "",
    dateTo: "",
  })
  const [noticeData, setNoticeData] = useState()

  const handleDownload = () => {
    request?.mnid &&
      request?.dateFrom &&
      request?.dateTo &&
      DownloadApiCall(request, "download/download-track", "_self")
  }

  const handleDownloadTesting = () => {
    request?.mnid &&
      request?.dateFrom &&
      request?.dateTo &&
      DownloadApiCall(request, "download/download-track-testing", "_self")
  }

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
      withCount: 0,
    }
    axios.post(ApiRoute + "assignedNotice", requestPayload).then(result => {
      if (result?.data?.message === "Success") {
        const data = result?.data?.responseData?.map(item => ({
          label: item?.notice_type,
          value: item?.notice_id,
        }))
        setNoticeData(data)
      }
    })
  }, [])

  return (
    <div className="card">
      <div className="card-header">
        <h5>Download Track Report</h5>
      </div>
      <div className="card-Body m-2">
        <Grid fluid>
          <Row className="show-grid">
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <FormGroup
                select={true}
                datalabel="Notice:"
                selectData={noticeData}
                datavalue={request?.mnid}
                onChange={e => setRequest({ ...request, mnid: e })}
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <span>From</span>
              <Input
                type="date"
                name="dateFrom"
                id="dateFrom"
                onChange={e => setRequest({ ...request, dateFrom: e })}
                block
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <span>To</span>
              <Input
                type="date"
                name="dateTo"
                id="dateTo"
                min={request?.dateFrom}
                onChange={e => setRequest({ ...request, dateTo: e })}
                block
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <Button
                appearance="primary"
                color="blue"
                style={{ marginTop: "1.2em" }}
                onClick={() => handleDownload()}
                block
              >
                Download
              </Button><br />
              <Button
                appearance="primary"
                color="orange"
                style={{ marginTop: "1.2em" }}
                onClick={() => handleDownloadTesting()}
                block
              >
                Download Testing
              </Button>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(DownloadReport)
