import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"
import axios from "axios"

// Pages Components
import Miniwidget from "./Miniwidget"
import { ApiCall } from "common/ActionApiCall/ApiCall"
// import MonthlyEarnings from "./montly-earnings";
// import EmailSent from "./email-sent";
// import MonthlyEarnings2 from "./montly-earnings2";
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";

//Import Action to copy breadcrumb items from local state to redux state
import { getUserMe, logOut, setBreadcrumbItems } from "../../../store/actions"
import { CustomProvider } from "rsuite"
import DownloadReport from "./DownloadReport"
import YearwiseDistribution from "./YearwiseDistribution"
import FilterDataChart from "./FilterDataChart"
import { useSelector } from 'react-redux'
import { IDs } from "common/ApiRoute"
import { UserActionService } from "Service/userActionService"
const UserDashboard = props => {
  const [lastLogin, setLastLogin] = useState("")
   const [noticeList,setNoticeList] = useState([])
  const dispatch = useDispatch()
  const id =  useSelector((state)=> state.Login.id)
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Dashboard", link: "#" },
  ]

  useEffect(() => {
    ApiCall({ token: props?.token }, "user/last-login", false, result => {
      setLastLogin(result.data.message)
    })
    const headers = {
      "Content-Type": "application/json",
    }
    let formData = new FormData()
    formData.append("token", props?.token)

    axios
      .post(RouteWithoutApi + "setOverdue", formData, {
        headers: headers,
      })
      .then(result => {
        console.log(result)
      })
    const requestPayload = {
      token: props?.token,
    }
    dispatch(
      getUserMe(requestPayload, result => {
        result?.data?.code !== 200 && dispatch(logOut())
      })
    )
  }, [])

  useEffect(() => {
    props.setBreadcrumbItems(
      `Dashboard >  Last Login : ${lastLogin}`,
      breadcrumbItems
    )
    UserActionService.getNoticeByUserId(id)
    .then(res => {
      const data = res?.data?.response?.map(item => ({
        label: item?.notice_name,
        value: item?.notice_id,
      }))
      setNoticeList(data)
    })
      
  },[])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard - User | Incase360</title>
      </MetaTags>

      <CustomProvider theme="dark">
        { 
        IDs.includes(id) ?  (
          <> <YearwiseDistribution/>
           <FilterDataChart noticeList={noticeList} /></>
        ) :
        <></>
        }
      
        <DownloadReport />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserDashboard)
