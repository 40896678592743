
import React, {useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container, Table ,   Button,
  Form,
  Label,
  Input,
  FormText,
} from "reactstrap"
import { Col, Grid, Row } from "rsuite"
import "./dispute.scss"
import { connect, useDispatch } from "react-redux";
// Redux
import {
  withRouter,
  Link,
  useHistory,
  Redirect,
  useParams,
} from "react-router-dom"

import { ApiRoute } from "common/ApiRoute"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import moment from 'moment';
import axios from "axios";
import { FileUploader } from "react-drag-drop-files"
import FormGroup from "components/Common/FormGroup"


function DisputeAmount() {

  const dispatch = useDispatch()
  const api_url = ApiRoute
  const history = useHistory()
  const params = useParams()
  const [disputeReply, setDisputeReply] = useState('');
  const [reply, setReply] = useState('');
  const [partyName, setpartyName] = useState('');
  const [noticeId, setnoticeId] = useState('');
  const [notice_unq_id, setnotice_unq_id] = useState([]);
  const [replyData, setReplyData] = useState('');
  const [request, setRequest] = useState({
    token: null,
    notice_id: null,
    your_reply: null, 
    party_name: null,
    files: null,
  })
  const [replyFile, setReplyFile] = useState('');
  const [action, setAction] = useState(false)

  useEffect(() => {


      var values = {
        notice_id: params.notice_id,
        token: params.token,
      }
      axios.post(api_url + "singlenoticeDetails", values).then(response => {
        setnotice_unq_id(response?.data?.responseData?.noticeData.notice_unq_id),
        setReplyData(response?.data?.responseData?.replyresult),
        setpartyName(response?.data?.responseData?.replyresult.party_name),
          setRequest({
            ...request,
            party_name : response?.data?.responseData?.replyresult.party_name,
            notice_id : params.notice_id,
            token : params.token,
          })
      })
  }, []);


/*   const handleSubmit = async (e) => {
      e.preventDefault();

    try {
      var values = {
        your_reply: reply,
        party_name: partyName,
        notice_id: params.notice_id,
        token: params.token,
        files: replyFile
      }
      axios.post(api_url + "addDisputeReply", request).then(response => {
        setDisputeReply(response?.data?.responseData),
          setRequest({
            ...request,
            data : response?.data?.responseData,
          })
          if (response?.data?.response === "success") {
            ShowSwal(
              "Success",
              "Your reply has been submitted",
              "info",
              "Ok"
            )
          } else {
            ShowNotification(response?.data?.message, "danger")
          }
      })
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };
  const handleClick = (param) => {
    let history = useHistory();

  } */


  const handleSubmit = async (e) => {

    e.preventDefault();
    
      ApiCall(request, "addDisputeReply", false, result => {
        alert(result?.data?.responseData);

        if (result?.data?.response === "success" ) {
          setRequest({
            ...request,
            files: null,
            party_name: null,
            your_reply: null,
          })
          setAction(!action)
          ShowNotification(result?.data?.responseData, "success")
        } else {
          ShowNotification(result?.data?.responseData, "danger")
        }
      })
  }
  
  return (
    <div>
    <Card>
    <Grid fluid>
            <Row className="show-grid text-center">
              <Col xs={24} sm={24} md={24}>
                {/* <h2>
                  <b> Reply</b>
                </h2>  */}
                <hr className="headflatline"></hr>
              </Col>
            </Row>
            <></>
          </Grid>
      <Grid fluid>
        <CardBody className="p-4 body">
{/*           <h4>
          Dispute Amount
          </h4> */}
         {/*  <p>
          If you believe there is an error with the amount of payment, you can contact your lender or servicer to ask for clarification or disputing any charges you believe are incorrect.
          </p> */}
          <div className="row mb-3">
            <div className="col-md-12 text-center">
            <h5> We're here to help! If you have any concerns or questions, we're just a message away.</h5
            
            >
              <p>Simply write down your concern in the message box below, and our dedicated support team will get back to you as soon as possible. We value your feedback and strive to provide you with the best possible assistance.</p>
            </div>
          </div>
        </CardBody>
{/*         <CardBody>
        <div className="row mb-3">
        <div className="col-md-12 text-center">
         <a href={"http://localhost/incase360/assets/upload/userGnrtdNotice/"+replyData?.notice_pdf} target="_blank">
            <Button className="mt-4" appearance="primary" block>
              View Notice / Communication
            </Button>
          </a>
          </div>
        </div>
        </CardBody> */}
        <CardBody className="p-4 body">
        {/*   <form onSubmit={handleSubmit}> */}

          <div className="row mb-3">
            <div className="col-md-6">
              <Input
                name="noticeId"
                type="text"
                value={notice_unq_id}
                onChange={(e) => setnotice_unq_id(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <Input
                name="partyName"
                type="text"
                value={request?.party_name || "" }
                //datavalue={request?.party_name || ""}
                placeholder="Enter Party Name"
                onChange={e =>
                  setRequest({ ...request, party_name: e.target.value })
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Input
              type="textarea"
              name="reply"
              id="reply"
              placeholder=" Reply"
              value={request?.your_reply || ""  }
              onChange={e =>
                setRequest({ ...request, your_reply: e.target.value })
              }
              rows={5}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className=" d-flex justify-content-center">
              <FileUploader
                name="file"
                onTypeError={e =>
                  e && setRequest({ ...request, files: null })
                }
                handleChange={e =>
                  setRequest({ ...request, files: e })
                }
              />

              </div>
            </div>
          <Row className="show-grid text-center">
            {/* <Button
              type="submit"
              className="my-4"
              color="warning"
              size="md"
            
            >
              Submit
            </Button>{" "} */}
               <Button
                appearance="primary"
                onClick={handleSubmit}
                color="warning"
                              >
                Submit
              </Button>
          </Row>
          {/* </form> */}
        </CardBody>
      </Grid>
      </Card>
    </div>
  )
}

export default DisputeAmount
