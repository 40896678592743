import CheckAccess from "components/Common/CheckAccess"
import AddNewNotice from "pages/Admin/AddNewNotice"
import AddNewOffers from "pages/Admin/AddNewOffers"
import AddNewProxyNotice from "pages/Admin/AddNewProxyNotice"
import AdminAddLetterHead from "pages/Admin/AdminAddLetterHead"
import AdminDashboard from "pages/Admin/AdminDashboard"
import AdminFileSharing from "pages/Admin/AdminFileSharing"
import AdminLetterheadApproval from "pages/Admin/AdminLetterheadApproval"
import AdminNoticeDetails from "pages/Admin/AdminNoticeDetails"
import AdminActive from "pages/Admin/AdminNoticeDetails/AdminActive"
import AdminDraft from "pages/Admin/AdminNoticeDetails/AdminDraft"
import AdminEscalated from "pages/Admin/AdminNoticeDetails/AdminEscalated"
import AdminOverdue from "pages/Admin/AdminNoticeDetails/AdminOverdue"
import AdminResolved from "pages/Admin/AdminNoticeDetails/AdminResolved"
import AdminNoticeList from "pages/Admin/AdminNoticeList"
import AdminNotification from "pages/Admin/AdminNotification"
import AdminOffersList from "pages/Admin/AdminOffersList"
import AdminPartyDetails from "pages/Admin/AdminPartyDetails"
import AdminProfile from "pages/Admin/AdminProfile"
import AdminProxyNoticeList from "pages/Admin/AdminProxyNoticeList"
import AdminProxyReport from "pages/Admin/AdminProxyReport"
import AdminReport from "pages/Admin/AdminReport"
import AutoDownload from "pages/Admin/AutoDownload"
import AutoReport from "pages/Admin/AutoReport"
import CanvaReport from "pages/Admin/CanvaReport"
import EditNotice from "pages/Admin/EditNotice"
import ExistingNotice from "pages/Admin/ExistingNotice"
import Settings from "pages/Admin/Settings"
import UploadCsv from "pages/Admin/UserAction/UploadCsv"
import AdminBulkSend from "pages/Admin/UserAction/UploadCsv/bulkSend"
import AdminApiNotice from "pages/Admin/UserAction/UploadCsv/api_notice/index"
import UserApproved from "pages/Admin/UserApproved"
import UserDetails from "pages/Admin/UserDetails"
import UserLetterhead from "pages/Admin/UserLetterhead"
import UserNewRequest from "pages/Admin/UserNewRequest"
import UserUnapproved from "pages/Admin/UserUnapproved"
import Verify from "pages/Authentication/Verify"
import Cookie from "pages/Home/Cookie"
import DisputeAmount from "pages/Home/DisputeAmount"
import Paynow from "pages/Home/Paynow"
import Privacy from "pages/Home/Privacy"
import Restructure from "pages/Home/Restructure"
import Terms from "pages/Home/Terms"
import EditNoticeContent from "pages/User/EditNoticeContent"
import Letterhead from "pages/User/Letterhead"
import UserList from "pages/User/SubUser/UserList"
import UserActive from "pages/User/UserActive"
import UserAddLetterHead from "pages/User/UserAddLetterHead"
import UserDashboard from "pages/User/UserDashboard"
import UserDraft from "pages/User/UserDraft"
import UserEscalated from "pages/User/UserEscalated"
import UserFileSharing from "pages/User/UserFileSharing"
import UserNoticeList from "pages/User/UserNoticeList"
import UserNotification from "pages/User/UserNotification"
import UserOverdue from "pages/User/UserOverdue"
import UserPartyDetails from "pages/User/UserPartyDetails"
import UserPartyReply from "pages/User/UserPartyReply"
import UserProfile from "pages/User/UserProfile"
import UserRefIdSearch from "pages/User/UserRefIdSearch"
import UserRejected from "pages/User/UserRejected"
import UserReport from "pages/User/UserReport"
import UserResolved from "pages/User/UserResolved"
import UserScheduledDraft from "pages/User/UserScheduledDraft"
import UserTrack from "pages/User/UserTrack"

import Login from "../pages/Authentication/Login"
import Register from "../pages/Authentication/Register"
import ApiNoticePartyDetails from "pages/Admin/UserAction/UploadCsv/api_notice/ApiNoticePartyDetails"

// Authentication related pages
// import Logout from "../pages/Authentication/Logout"
const homeRoutes = [
  { path: "/terms-of-use", component: Terms },
  { path: "/privacy-policy", component: Privacy },
  { path: "/cookie-policy", component: Cookie },
  { path: "/paynow", component: Paynow },
  { path: "/dispute-amount", component: DisputeAmount },
  { path: "/restructure/:notice_id/:token", component: Restructure },
]

const authRoutes = [
  // { path: "/", component: MainPage },
  { path: "/register", component: Register },
  { path: "/register-verify", component: Verify },
  { path: "/login", component: Login },
  { path: "/access", component: CheckAccess },
]

const adminRoutes = [
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/noticelist", component: AdminNoticeList },
  { path: "/admin/proxy-noticelist", component: AdminProxyNoticeList },
  { path: "/admin/add-user-notice", component: AddNewNotice },
  { path: "/admin/add-proxy-notice", component: AddNewProxyNotice },
  { path: "/admin/offer/add-new-offer", component: AddNewOffers },
  { path: "/admin/offer/offers-list", component: AdminOffersList },
  { path: "/admin/auto-report", component: AutoReport },
  { path: "/admin/auto-download", component: AutoDownload },
  { path: "/admin/users/newrequest", component: UserNewRequest },
  { path: "/admin/users/approved", component: UserApproved },
  { path: "/admin/users/unapproved", component: UserUnapproved },
  { path: "/admin/existingnotice", component: ExistingNotice },
  { path: "/admin/notice/edit/:id", component: EditNotice },
  { path: "/admin/noticedetails/:id", component: AdminNoticeDetails },
  { path: "/admin/filesharing", component: AdminFileSharing },
  { path: "/admin/party-details/:id", component: AdminPartyDetails },
  { path: "/admin/letterheadApproval", component: AdminLetterheadApproval },
  { path: "/admin/profile", component: AdminProfile },
  { path: "/admin/user-details/:id", component: UserDetails },
  { path: "/admin/user-letterhead/:id", component: UserLetterhead },
  { path: "/admin/draft-notice/:id", component: AdminDraft },
  { path: "/admin/active-notice/:id", component: AdminActive },
  { path: "/admin/overdue-notice/:id", component: AdminOverdue },
  { path: "/admin/escalated-notice/:id", component: AdminEscalated },
  { path: "/admin/resolved-notice/:id", component: AdminResolved },
  { path: "/admin/add-letterhead/:id", component: AdminAddLetterHead },
  { path: "/admin/settings", component: Settings },
  { path: "/admin/notification", component: AdminNotification },
  { path: "/admin/report", component: AdminReport },
  { path: "/admin/proxy-report", component: AdminProxyReport },
  { path: "/admin/canva-report/:id", component: CanvaReport },
  { path: "/admin/user-action/upload-csv", component: UploadCsv },
  { path: "/admin/user-action/bulk-send", component: AdminBulkSend },
  { path: "/admin/user-action/api-notice", component: AdminApiNotice },
  { path: "/admin/user-action/party-details/:id", component: ApiNoticePartyDetails },
]

const userRoutes = [
  { path: "/user/dashboard", component: UserDashboard },
  { path: "/user/subuser/noticelist/:id", component: UserNoticeList },
  { path: "/user/report", component: UserReport },
  { path: "/user/refidsearch", component: UserRefIdSearch },
  { path: "/user/filesharing", component: UserFileSharing },
  { path: "/user/draft-notice/:id/:userId", component: UserDraft },
  { path: "/user/scheduled-notice/:id/:userId", component: UserScheduledDraft },
  { path: "/user/active-notice/:id/:userId", component: UserActive },
  { path: "/user/overdue-notice/:id/:userId", component: UserOverdue },
  { path: "/user/escalated-notice/:id/:userId", component: UserEscalated },
  { path: "/user/resolved-notice/:id/:userId", component: UserResolved },
  { path: "/user/rejected-notice/:id/:userId", component: UserRejected },
  { path: "/user/party-details/:id", component: UserPartyDetails },
  { path: "/user/profile", component: UserProfile },
  { path: "/user/letterhead", component: Letterhead },
  { path: "/user/party-reply/:id", component: UserPartyReply },
  { path: "/user/edit-Notice/:noticeId/:id", component: EditNoticeContent },
  { path: "/user/add-letterhead", component: UserAddLetterHead },
  { path: "/user/track/:id", component: UserTrack },
  { path: "/user/subuser", component: UserList },
  { path: "/user/noticelist", component: UserNoticeList },
  // { path: "/user/:sub", component: UserNoticeList },
  { path: "/user/draft-notice/:id", component: UserDraft },
  { path: "/user/scheduled-notice/:id", component: UserScheduledDraft },
  { path: "/user/active-notice/:id", component: UserActive },
  { path: "/user/overdue-notice/:id", component: UserOverdue },
  { path: "/user/escalated-notice/:id", component: UserEscalated },
  { path: "/user/resolved-notice/:id", component: UserResolved },
  { path: "/user/rejected-notice/:id", component: UserRejected },
  { path: "/user/notification", component: UserNotification },
]

export { homeRoutes, authRoutes, userRoutes, adminRoutes }
